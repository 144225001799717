import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import { getUsersByCoach, loadCalendarEventsByUser, fetchGroupInfo, 
    clearCalendarEvents, getGroupsAssignedToCoach, loadCalendarEventsByGroup, deleteGroup,
    addUserToGroup, loadUsersInGroup, removeUserFromGroup,  
    clearGroupInfo} from '../actions/auth';
import MyCalendar from '../components/Calendar'; // Import your Calendar component
import { Link } from 'react-router-dom';
import getLPTheme from '../containers/LandingPage/getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import '../styles/styles.css';



const LPtheme = createTheme(getLPTheme('light'));

const CoachDashboard = ({ coachId, user, usersByCoach, deleteGroup,
    getUsersByCoach, loadCalendarEventsByUser, loadCalendarEventsByGroup, 
    calendarEvents, clearCalendarEvents, addUserToGroup, clearGroupInfo,
    groupsAssignedToCoach, getGroupsAssignedToCoach, fetchGroupInfo, removeUserFromGroup,
    groupInfo, loadUsersInGroup, usersInGroup, displayedMonth  }) => {
        const [selectedUser, setSelectedUser] = useState(null);
        const [selectedTeam, setSelectedTeam] = useState(null);
        const [athleteOrTeam, setAthleteOrTeam] = useState(null);
        const [isManageTeamModalOpen, setIsManageTeamModalOpen] = useState(false);
        const [requestForDeleteTeamSent, setRequestForDeleteTeamSent] = useState(false);
        const [filterGroupText, setFilterGroupText] = useState('');
        const [athleteFilterText, setAthleteFilterText] = useState('');
        const [athleteInGroupFilterText, setAthleteInGroupFilterText] = useState('');
      

        
  const filteredAthletes = usersByCoach.filter(user =>
    `${user.first_name} ${user.last_name}`.toLowerCase().includes(athleteFilterText.toLowerCase())
  );

  const filteredGroups = Array.isArray(groupsAssignedToCoach)
  ? groupsAssignedToCoach.filter(group =>
      group.name.toLowerCase().includes(filterGroupText.toLowerCase())
    )
  : [];
      
    
  const toggleManageTeamModal = () => {
    setIsManageTeamModalOpen(!isManageTeamModalOpen);

  };

    useEffect(() => {
        if (user) {
            getUsersByCoach(coachId);
            getGroupsAssignedToCoach(coachId);
        }
    }, [getUsersByCoach, getGroupsAssignedToCoach, coachId, user]);

    useEffect(() => {
        // Cleanup function
        const clearEventsAndLoad = async () => {
            clearCalendarEvents();
            if (selectedUser && selectedUser.id) {
                await loadCalendarEventsByUser(selectedUser.id);
            }
        };

        clearEventsAndLoad();

        // Cleanup function will be called when component unmounts or selectedUser changes
        return clearCalendarEvents;
    }, [clearCalendarEvents, loadCalendarEventsByUser, selectedUser]);

    const handleRemoveUser = (userId) => {
        const isConfirmed = window.confirm('Are you sure you want to remove user from this Group?');
        if (isConfirmed && groupInfo) {
          removeUserFromGroup(groupInfo.id, userId).then(() => {
            // After successful removal, reload the list of users in the group
            loadUsersInGroup(groupInfo.id);
          })
          .catch((error) => {
            // Handle any errors here, such as displaying an error message
            console.error('Error removing user from group:', error);
          });
        }
      };

      const handleAddUserToGroup = (userId) => {
        if (userId && groupInfo) {
          addUserToGroup(groupInfo.id, userId);
        }
      };
      

    const handleDeleteGroup = () => {
        const isConfirmed = window.confirm('Are you sure you want to delete this group? This action cannot be undone. All events and users in the group will be removed.');
        if (isConfirmed) {
          try{
            deleteGroup(groupInfo.id);
            setRequestForDeleteTeamSent(true);
            getGroupsAssignedToCoach(coachId);
            // Refresh the page after successful deletion
            window.location.reload();
          }
          catch(err){
            console.log(err);
          }
        }
      };
    
    const usersNotInGroup = usersByCoach.filter(
        (user) => !usersInGroup.some((groupUser) => groupUser.id === user.id)
    );

        
    const filteredUsersNotInGroup = usersNotInGroup.filter(user =>
      `${user.first_name} ${user.last_name}`.toLowerCase().includes(athleteInGroupFilterText.toLowerCase())
    );

    const handleCloseTeamManagementModal = () => {
        setIsManageTeamModalOpen(false); // Close the modal
    };
    
    const handleUserCalendar = (e) => {
        setAthleteOrTeam('athlete');
        clearCalendarEvents();
        loadCalendarEventsByUser(e.id);
        setSelectedUser(e);
        setSelectedTeam(null);
        clearGroupInfo();
    };

    const handleTeamCalendar = (e) => {
        groupInfo = e;
        console.log(groupInfo.id);
        console.log(e);
        if (e.id) {
          fetchGroupInfo(e.id);
            loadUsersInGroup(e.id);
            setAthleteOrTeam('team');
            clearCalendarEvents();
            loadCalendarEventsByGroup(e.id);
            setSelectedTeam(e);
            setSelectedUser(null);
        }
        //console.log(selectedTeam);
    };
    const modalStyles = {
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.75)', // Change overlay background color and opacity
          zIndex: 1000, // Set a higher z-index to ensure modal is on top of other elements
        },
        content: {
          width: '60%', // Set the width of the modal
          maxWidth: '500px', // Set a maximum width
          margin: '0 auto', // Center the modal horizontally
          padding: '20px', // Add padding for better spacing
        },
      };
    return (
        <ThemeProvider theme={LPtheme}>
          <CssBaseline />
          <div className="custom-container">
            <h2 className="mt-4">{user && user.first_name} {user && user.last_name}'s Dashboard</h2>
            <div className="row">
             
            <div className="col-md-4">
              <div className="mt-3">
                <h5>Athletes</h5>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Filter athletes..."
                  value={athleteFilterText}
                  onChange={(e) => setAthleteFilterText(e.target.value)}
                />
                {filteredAthletes.length > 0 ? (
                  <ul
                    className="list-group bg-transparent"
                    style={{ maxHeight: '250px', overflowY: 'auto', backgroundColor: '#F3F7F4' }}
                  >
                    {filteredAthletes.map((assignedUser) => (
                      <li
                        key={assignedUser.id}
                        className="list-group-item d-flex justify-content-between align-items-center bg-transparent"
                      >
                        <div>
                          {assignedUser.first_name} {assignedUser.last_name}
                        </div>
                        <div className="d-flex align-items-center">
                          <Link to={`/about/${assignedUser.id}`} className="btn btn-secondary mr-2">
                            Profile
                          </Link>
                          <button className="btn btn-primary" onClick={() => handleUserCalendar(assignedUser)}>
                            Calendar
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No users assigned to this coach.</p>
                )}
              </div>
                
                <div className="mt-4">
                  <h5>Teams</h5>
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Filter teams..."
                    value={filterGroupText}
                    onChange={(e) => setFilterGroupText(e.target.value)}
                  />
                  {filteredGroups.length > 0 ? (
                    <ul className="list-group" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                      {filteredGroups.map((group) => (
                        <li
                          key={group.id}
                          className="list-group-item d-flex justify-content-between align-items-center bg-transparent"
                        >
                          <div>{group.name}</div>
                          <div className="d-flex align-items-center">
                            {selectedTeam && selectedTeam.id === group.id && (
                              <button
                                className="btn btn-secondary mr-2"
                                onClick={toggleManageTeamModal}
                              >
                                Manage
                              </button>
                            )}
                            <button
                              className="btn btn-primary"
                              onClick={() => handleTeamCalendar(group)}
                            >
                              Calendar
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No teams found.</p>
                  )}
                </div>
                </div>
              
              <div className="col-md-8">
                {selectedTeam || selectedUser ? (
                  <div className="mt-4">
                    {athleteOrTeam === 'athlete' ? (
                      <MyCalendar key={JSON.stringify(calendarEvents)} events={calendarEvents} selectedUser={selectedUser} user={user} athleteOrTeam={athleteOrTeam}/>
                    ) : (
                      <MyCalendar 
                        key={JSON.stringify(calendarEvents)} 
                        events={calendarEvents}
                        user={user}
                        group={groupInfo.id}
                        groupName={groupInfo.name}
                        usersInGroup={usersInGroup}
                        athleteOrTeam={athleteOrTeam}
                      />
                    )}
                  </div>
                ) : (
                  <div className="mt-4" style={{ fontSize: '36px', textAlign: 'center' }}>Choose a calendar</div>
                )}
              </div>

            </div>
          </div>
          <div>
            
          <Modal isOpen={isManageTeamModalOpen} onRequestClose={toggleManageTeamModal} style={modalStyles}>
            <div className="row">
              <div className="col-md-12">
                <h2>{groupInfo.name} Team Management</h2>
                <div className="mt-4">
                  <h5>Athletes in Team</h5>
                  {usersInGroup.length > 0 ? (
                    <ul className="list-group" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                      {usersInGroup.map((user) => (
                        <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
                          {user.first_name} {user.last_name}
                          <button className="btn btn-danger" onClick={() => handleRemoveUser(user.id)}>
                            Remove
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Athletes in this Team.</p>
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <h5>Add Athlete to Team</h5>
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Filter athletes..."
                  value={athleteInGroupFilterText}
                  onChange={(e) => setAthleteInGroupFilterText(e.target.value)}
                />
                {filteredUsersNotInGroup.length > 0 ? (
                  <ul className="list-group" style={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {filteredUsersNotInGroup.map((user) => (
                    <li key={user.id} className="list-group-item d-flex justify-content-between align-items-center">
                      {user.first_name} {user.last_name}
                      <button className="btn btn-success" onClick={() => handleAddUserToGroup(user.id)}>
                        Add
                      </button>
                    </li>
                  ))}
                </ul>
                
                ) : (
                  <p>No athletes found.</p>
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12">
                <button onClick={handleDeleteGroup} className="btn btn-danger">
                  Delete Team
                </button>
              </div>
            </div>

  <div className="row mt-4">
    <div className="col-md-12 text-center">
      <button onClick={handleCloseTeamManagementModal} className="btn btn-secondary mx-auto">
        Close
      </button>
    </div>
  </div>
</Modal>


            
            </div>
        </ThemeProvider>
      );
       
};

const mapStateToProps = (state) => ({
    coachId: state.auth.user ? state.auth.user.id : null,
    usersByCoach: state.auth.usersByCoach || [],
    user: state.auth.user,
    calendarEvents: state.auth.calendarEvents,
    groupsAssignedToCoach: state.auth.groupsAssignedToCoach || [],
    groupInfo: state.auth.groupInfo || [],
    usersInGroup: state.auth.usersInGroup || [],
});

const mapDispatchToProps = (dispatch) => ({
    getUsersByCoach: (coachId) => dispatch(getUsersByCoach(coachId)),
    loadCalendarEventsByGroup: (groupId) => dispatch(loadCalendarEventsByGroup(groupId)),
    loadCalendarEventsByUser: (userId) => dispatch(loadCalendarEventsByUser(userId)),
    getGroupsAssignedToCoach: (coachId) => dispatch(getGroupsAssignedToCoach(coachId)), 
    clearCalendarEvents: () => dispatch(clearCalendarEvents()),
    clearGroupInfo: () => dispatch(clearGroupInfo()),
    fetchGroupInfo: (groupId) => dispatch(fetchGroupInfo(groupId)),
    loadUsersInGroup: (groupId) => dispatch(loadUsersInGroup(groupId)),
    removeUserFromGroup: (groupId, userId) => dispatch(removeUserFromGroup(groupId, userId)),
    addUserToGroup: (groupId, userId) => dispatch(addUserToGroup(groupId, userId)),
    deleteGroup: (groupId) => dispatch(deleteGroup(groupId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CoachDashboard);
