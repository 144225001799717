import React, { useState, useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { createGroup, fetchPendingInvitations, removeInvitation, inviteUser, inviteMultipleUsers, getGroupsAssignedToCoach } from '../actions/auth'; // Assuming the action to fetch pending invitations is defined

//import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import getLPTheme from '../containers/LandingPage/getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Typography, TextField, Modal} from '@mui/material';


const LPtheme = createTheme(getLPTheme('light'));

const CoachProfile = ({ user, createGroup, coachId, fetchPendingInvitations, 
    pendingRequests, groupsAssignedToCoach, getGroupsAssignedToCoach }) => {
    const [inviteEmail, setInviteEmail] = useState('');
    const [groupName, setGroupName] = useState('');
    const [requestSent, setRequestSent] = useState(false);

    const [inviteMessage, setInviteMessage] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const [openAdvancedInviteModal, setOpenAdvancedInviteModal] = useState(false); // State for modal visibility
    const [multipleEmails, setMultipleEmails] = useState(['']); // State for multiple emails input
    const [errorMessages, setErrorMessages] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState('');
    const [filterGroupText, setFilterGroupText] = useState('');
    const [createGroupMessage, setCreateGroupMessage] = useState('');

    const dispatch = useDispatch();

    const bottomRef = useRef(null); // Ref to scroll to the bottom

    useEffect(() => {
        // Scroll to the bottom when multipleEmails changes
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [multipleEmails]); // Trigger on change of multipleEmails array


    

    useEffect(() => {
        if (coachId) {
            fetchPendingInvitations(coachId); // Fetch pending invitations when component mounts
            getGroupsAssignedToCoach(coachId);
        }
    }, [coachId, fetchPendingInvitations, getGroupsAssignedToCoach]);



    const filteredGroups = Array.isArray(groupsAssignedToCoach)
    ? groupsAssignedToCoach.filter(group =>
        group.name.toLowerCase().includes(filterGroupText.toLowerCase())
      )
    : [];
    

    const handleRemoveInvitation = (reciever_email) => {
        //console.log(reciever_email);
        dispatch(removeInvitation(coachId, reciever_email));
    };

    const handleCreateGroup = async (e) => {
        e.preventDefault();

        if (groupName) {
            const response = await createGroup({ name: groupName, coach: coachId, about: '' }); // Call the createGroup action
            console.log(response);
            setGroupName(''); // Clear the group name input
            if (response && response.status === 201) {  
                setCreateGroupMessage(`${groupName} created!`); 
            } else {
                setCreateGroupMessage(`Failed to make ${groupName}.`);;
            }
        }
        // Clear the message after 10 seconds
        setTimeout(() => {
            setCreateGroupMessage('');
        }, 10000);
        setRequestSent(true);
    };

    const handleInviteSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
    
        if (inviteEmail && validateEmail(inviteEmail)) {
            setIsProcessing(true); // Set processing state
    
            // Dispatch inviteUser action and wait for response
            const response = await dispatch(inviteUser(user, inviteEmail));
             
            if (response && response.message) {
                setInviteMessage(response.message);
                setIsProcessing(false);
            }
            
            // Clear the processing state and invite message after 10 seconds
            const id = setTimeout(() => {
                setInviteMessage(''); // Clear invite message
                setIsProcessing(false); // Clear processing state
            }, 10000);
    
            setTimeoutId(id);
    
            setInviteEmail(''); // Clear the email input
        }
    };


    const handleInviteMultiple = async (e) => {
        e.preventDefault(); // Prevent the default form submission

         // Validate and filter the emails from the state
        const emailArray = multipleEmails.map(email => email.trim()).filter(email => validateEmail(email));
        console.log(emailArray);    
        
        if (emailArray.length > 0) {
            setIsProcessing(true);
            setOpenAdvancedInviteModal(false);
            // Dispatch createMultipleInvitations action
            const coach_id = user.id;
            const response = await dispatch(inviteMultipleUsers(coach_id, emailArray, selectedGroup.id ? selectedGroup.id : null));
            console.log(response);
            
            if (response && response.message) {
                setInviteMessage(response.message);
                setErrorMessages(response.errors || []);
                setIsProcessing(false);
            }
            
            // Clear the processing state and invite message after 10 seconds
            const id = setTimeout(() => {
                setInviteMessage(''); // Clear invite message
                //setErrorMessages([]); // Clear error messages
                setIsProcessing(false); // Clear processing state
            }, 10000);
            
            setTimeoutId(id);
            setMultipleEmails(['']); // Clear the email input

        } else {
            setInviteMessage('No valid emails to invite');
            setIsProcessing(false);
            setOpenAdvancedInviteModal(false);
        }
        
         // Close modal after submission
    };

    const handleChange = (index, event) => {
        const newEmails = [...multipleEmails];
        newEmails[index] = event.target.value;
        setMultipleEmails(newEmails);
    };
    
    const handleAddField = () => {
        const hasEmptyField = multipleEmails.some(email => email.trim() === '');
        if (!hasEmptyField) {
            setMultipleEmails([...multipleEmails, '']); // Add a new empty field only when all current fields are filled
        }
    };
    
    // This function ensures that a new field is added only when the current field is no longer focused
    const handleBlur = (index) => {
        if (multipleEmails[index].trim() !== '') {
            handleAddField(); // Add a new empty field if the current field has valid input
        }
    };

    const handleRemoveField = (index) => {
        const newEmails = multipleEmails.filter((_, i) => i !== index);
        setMultipleEmails(newEmails);
    };



    
    const validateEmail = (email) => {
        // Simple regex for email validation
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return re.test(email);
    };


    const handleOpenAdvancedInviteModal = () => {
        setOpenAdvancedInviteModal(true);
        setSelectedGroup('');
    }
    const handleCloseAdvancedInviteModal = () => setOpenAdvancedInviteModal(false);

    const handleTeamSelection = (group) => {
        setSelectedGroup(group);
    };

    if (!user) {
        return <Navigate to="/login" />;
    }

    return (
        <div>
        <style>

    {`
        .list-group-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
        
        .bg-selected {
            background-color: #cce5ff; /* Adjust to your preferred selected background color */
        }
        
        .list-group-item .btn {
            margin-left: auto; /* Ensure the button is aligned to the right */
        }
        
        .form-control {
            width: 100%;
        }
    `}
        </style>
        
        
        <ThemeProvider theme={LPtheme}>
            <CssBaseline />
            <div className="container mt-4">
                <h2>Coach Profile</h2>
                {user ? (
                    <div>
                        <ul className="list-group">
                            <li className="list-group-item bg-transparent">Name: {user.first_name} {user.last_name}</li>
                            <li className="list-group-item bg-transparent">Email: {user.email}</li>
                            <li className="list-group-item bg-transparent">About: {user.about}</li>
                        </ul>
                    </div>
                ) : (
                    <p>Loading user data...</p>
                )}
                <div className="row">
                    <div className="col-md-4">
                        <div className="mt-4">
                            <h5>Create a New Team</h5>
                            <form onSubmit={handleCreateGroup} className="form-inline">
                                <input
                                    type="text"
                                    className="form-control mr-2"
                                    placeholder="Team Name"
                                    value={groupName}
                                    onChange={(e) => setGroupName(e.target.value)}
                                />
                                <button type="submit" className="btn btn-success">
                                    Create Team
                                </button>
                            </form>
                            {createGroupMessage && (
                                <div className={`alert ${createGroupMessage.includes('create') ? 'alert-success' : 'alert-danger'} mt-3`}>
                                    {createGroupMessage}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="mt-4">
                        <h5>Invite Athlete</h5>
                        <form onSubmit={handleInviteSubmit} className="form-inline">
                            <input
                                type="email"
                                className="form-control mr-2"
                                placeholder="Athlete's Email"
                                value={inviteEmail}
                                onChange={(e) => setInviteEmail(e.target.value)}
                            />
                            <button type="submit" className="btn btn-primary"
                            tyle={{
                                backgroundColor: '#007bff',
                                color: 'white',
                                border: 'none',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                marginRight: '8px'
                            }}>
                                Invite
                            </button>
                                <button type="button" className="btn btn-secondary ml-2" onClick={handleOpenAdvancedInviteModal}

                                style={{
                                    backgroundColor: '#6c757d',
                                    color: 'white',
                                    border: 'none',
                                    padding: '8px 16px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    fontSize: '16px'
                                }}
                                >
                                    More
                                </button>
                        </form>
                        {isProcessing && (
                            <div className="alert alert-info mt-3">
                                Processing...
                            </div>
                        )}
                        {inviteMessage && !isProcessing && (
                            <div className={`alert ${inviteMessage.includes('successfully') ? 'alert-success' : 'alert-danger'} mt-3`}>
                                {inviteMessage}
                            </div>
                        )}

                        {errorMessages.length > 0 && (
                            <div className="alert alert-danger mt-3">
                                {errorMessages.map((error, index) => (
                                    <div key={index}>{error}</div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="mt-4">
                        <h5>Pending Invitations</h5>
                        {pendingRequests && pendingRequests.length > 0 ? (
                            <Box 
                                sx={{ 
                                    maxHeight: '200px', 
                                    overflowY: 'auto', 
                                    border: '1px solid #ccc', 
                                    borderRadius: '5px', 
                                    padding: '10px',
                                    fontSize: '0.875rem' // Adjust font size here
                                }}
                            >
                                <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
                                    {pendingRequests.map((email, index) => (
                                        <li 
                                            key={index} 
                                            className="list-group-item d-flex justify-content-between align-items-center" 
                                            style={{ 
                                                fontSize: '0.95rem', // Smaller font size for list items
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div 
                                                style={{
                                                    flex: '1 1 auto',
                                                    whiteSpace: 'nowrap', // Prevent line break
                                                    overflow: 'hidden',   // Hide overflow
                                                    textOverflow: 'ellipsis', // Show ellipsis when overflow
                                                    marginRight: '10px'  // Space between email and button
                                                }}
                                                title={email} // Tooltip with full email on hover
                                            >
                                                {email}
                                            </div>
                                            <button 
                                                className="btn btn-danger" 
                                                onClick={() => handleRemoveInvitation(email)}
                                                style={{
                                                    flexShrink: 0 // Prevent the button from shrinking
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </Box>
                        ) : (
                            <Typography variant="body1" color="textSecondary">
                                No pending invitations
                            </Typography>
                        )}
                    </div>
                </div>


                <Modal
                    open={openAdvancedInviteModal}
                    onClose={handleCloseAdvancedInviteModal}
                    aria-labelledby="modal-title"
                    aria-describedby="modal-description"
                >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Typography id="modal-title" variant="h6" component="h2">
                            Invite Multiple Athletes (Press tab to add more emails)
                        </Typography>
                        <Box sx={{ maxHeight: '225px', overflowY: 'auto', mb: 2 }}>
                    {multipleEmails.map((email, index) => (
                        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                             <input
                        placeholder={`Email ${index + 1}`}
                        variant="outlined"
                        fullWidth
                        value={email}
                        onChange={(e) => handleChange(index, e)}
                        onBlur={() => handleBlur(index)}  // Trigger onBlur to add new field after losing focus
                        sx={{ mr: 1 }}
                        type="text"
                        className="form-control mr-2"
                    />
                            <button
                                type="submit"
                                className="btn btn-danger"
                                onClick={() => handleRemoveField(index)}
                                disabled={multipleEmails.length === 1}
                            >
                                Remove
                            </button>
                        </Box>
                    ))}
                    {/* This div is used as a reference to scroll to */}
                    <div ref={bottomRef}></div>
                </Box>
                {/* <button
                                    className="btn btn-primary" 
                    onClick={handleAddField}
                    sx={{ mt: 2 }}
                    disabled={multipleEmails.some(email => email.trim() === '')} 
                >
                    Add Another Email
                </button> */}
        <Box sx={{ mt: 4, width: '100%' }}>
            <Typography variant="h6">Auto Assign to Team (Optional)</Typography>
            <TextField
                label="Filter teams..."
                variant="standard"
                fullWidth
                value={filterGroupText}
                onChange={(e) => setFilterGroupText(e.target.value)}
                sx={{ mb: 2 }}
                InputLabelProps={{disabled: true}}
            />
            <Box 
                sx={{ 
                    maxHeight: '200px', 
                    overflowY: 'auto', 
                    border: '1px solid #ccc', 
                    borderRadius: '5px', 
                    padding: '10px',
                    fontSize: '0.875rem'
                }}
            >
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {filteredGroups.length > 0 ? (
                        filteredGroups.map((group) => (
                            <li 
                                key={group.id} 
                                className="list-group-item d-flex justify-content-between align-items-center" 
                                style={{ 
                                    fontSize: '0.95rem',
                                    backgroundColor: selectedGroup && selectedGroup.id === group.id ? '#cce5ff' : 'transparent'
                                }}
                            >
                                <div>{group.name}</div>
                                <button 
                                    className="btn btn-primary" 
                                    onClick={() => handleTeamSelection(group)}
                                    style={{ marginLeft: 'auto' }}
                                >
                                    Select
                                </button>
                            </li>
                        ))
                    ) : (
                        <Typography>No teams found.</Typography>
                    )}
                </ul>
            </Box>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2, width: '100%' }}>
              <button type="submit" className="btn btn-success"
                  onClick={handleInviteMultiple}
                  disabled={isProcessing}
              >
                  Invite
                  </button>
                    {/* Conditionally render the message when no team is selected */}
                    {!selectedGroup && (
                        <Typography sx={{ ml: 2, color: 'red' }}>
                            Athletes will not be assigned to any team.
                        </Typography>
                    )}
              <button type="submit" className="btn btn-danger"
                  onClick={handleCloseAdvancedInviteModal}
              >
                  Cancel
              </button>
          </Box>
        {/* Optional: Display processing and messages */}
    </Box>
</Modal>



        </div>
        </div>
        </ThemeProvider>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
    coachId: state.auth.user ? state.auth.user.id : null,
    pendingRequests: state.auth.pendingRequests,
    groupsAssignedToCoach: state.auth.groupsAssignedToCoach || [],
});

const mapDispatchToProps = (dispatch) => ({
    createGroup: (groupData) => dispatch(createGroup(groupData)),
    fetchPendingInvitations: (coachId) => dispatch(fetchPendingInvitations(coachId)),
    removeInvitation: (coachId, reciever_email) => dispatch(removeInvitation(coachId, reciever_email)),
    inviteUser: (user, reciever_email) => dispatch(inviteUser(user, reciever_email)),
    inviteMultipleUsers: (coach_id, emailArray) => dispatch(inviteMultipleUsers(coach_id, emailArray)),
    getGroupsAssignedToCoach: (coachId) => dispatch(getGroupsAssignedToCoach(coachId)), 
});

export default connect(mapStateToProps, mapDispatchToProps)(CoachProfile);
