import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../actions/auth';
import getLPTheme from '../containers/LandingPage/getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const LPtheme = createTheme(getLPTheme('light'));

const ResetPasswordConfirm = ({ reset_password_confirm }) => {
    const [requestSent, setRequestSent] = useState(false);
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    const { uid, token } = useParams(); 

    const { new_password, re_new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = e => {
        e.preventDefault();

        // Password strength validation
        if (new_password.length < 8 || !new_password.match(/[A-Z]/) || !new_password.match(/[!@#$%^&*]/)) {
            setError('Password must be at least 8 characters long, include a capital letter and a special character (!, @, $, etc).');
            return;
        }

        if (new_password !== re_new_password) {
            setError('Passwords do not match.');
            return;
        }

        reset_password_confirm(uid, token, new_password, re_new_password);
        setSuccessMessage('Your password has been reset. You will be redirected to the login page shortly.');
        setRequestSent(true);

        // Redirect to login after 5 seconds
        setTimeout(() => {
            setRedirectToLogin(true);
        }, 5000);
    };

    if (redirectToLogin) {
        return <Navigate to='/login' />;
    }

    return (
        <ThemeProvider theme={LPtheme}>
            <CssBaseline />
            <div className='container mt-5'>
                <form onSubmit={e => onSubmit(e)}>
                    {error && (
                        <div className="alert alert-danger mt-3">
                            {error}
                        </div>
                    )}
                    {successMessage && (
                        <div className="alert alert-success mt-3">
                            {successMessage}
                        </div>
                    )}
                    <div className='form-group'>
                        <input
                            className='form-control'
                            type='password'
                            placeholder='New Password'
                            name='new_password'
                            value={new_password}
                            onChange={e => onChange(e)}
                            minLength='8'
                            required
                        />
                    </div>
                    <div className='form-group'>
                        <input
                            className='form-control'
                            type='password'
                            placeholder='Confirm New Password'
                            name='re_new_password'
                            value={re_new_password}
                            onChange={e => onChange(e)}
                            minLength='8'
                            required
                        />
                    </div>
                    <button className='btn btn-primary' type='submit'>Reset Password</button>
                </form>
            </div>
        </ThemeProvider>
    );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
