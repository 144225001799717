import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { load_user, loadCalendarEventsByUser, getGroupsAssignedToUser, 
  clearCalendarEvents, handleInvitationCheck } from '../actions/auth';
import MyCalendar from '../components/Calendar';
import { Link } from 'react-router-dom';

import getLPTheme from './LandingPage/getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const LPtheme = createTheme(getLPTheme('light'));

const AthleteDashboard = ({ user, calendarEvents, load_user, loadCalendarEventsByUser, getGroupsAssignedToUser, groupsAssignedToUser, handleInvitationCheck }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  // Load user on component mount
  useEffect(() => {
    load_user();
  }, [load_user]);

  // Set user email once user data is available
  useEffect(() => {
    if (user && user.email) {
      setUserEmail(user.email);
    }
  }, [user]);

  // Handle the invitation check only once when user email is set
  useEffect(() => {
    if (userEmail && !isInitialized) {
      const handleInitialInvitationCheck = async () => {
        await handleInvitationCheck(userEmail);
        setIsInitialized(true);
      };
      handleInitialInvitationCheck();
    }
  }, [userEmail, handleInvitationCheck, isInitialized]);

  // Fetch user-specific data only after the invitation check is done
  useEffect(() => {
    if (user && user.id && isInitialized) {
      clearCalendarEvents();  // Explicitly call clearCalendarEvents
      loadCalendarEventsByUser(user.id);
      getGroupsAssignedToUser(user.id);
    }
  }, [user, isInitialized, loadCalendarEventsByUser, getGroupsAssignedToUser]);

  // Trigger re-render when groupsAssignedToUser updates
  useEffect(() => {
    if (groupsAssignedToUser) {
      // Perform any additional actions needed when groupsAssignedToUser updates, if any
    }
  }, [groupsAssignedToUser]);

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <div className='container mt-5'>
        {/* ...other user information */}
        <div style={{ marginTop: '20px' }}>
          <h3>Your Teams</h3>
          {Array.isArray(groupsAssignedToUser) && groupsAssignedToUser.length > 0 ? (
            <ul className="list-group">
              {groupsAssignedToUser.map((group) => (
                <li key={group.id} className="list-group-item d-flex justify-content-between align-items-center bg-transparent">
                  {group.name}
                  <Link to={`/group-user-view/${group.id}`} className="btn btn-secondary">
                    View Team
                  </Link>
                </li>
              ))}
            </ul>
          ) : (
            <p>No teams assigned</p>
          )}

          <br />
          <br />
          <MyCalendar
            key={JSON.stringify(calendarEvents)} 
            events={calendarEvents}
            user={user}
            group={null}
            selectedUser={user}
            readOnly={true} 
            athleteOrTeam={'athlete'}
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = state => ({
  user: state.auth.user,
  calendarEvents: state.auth.calendarEvents,
  groupsAssignedToUser: state.auth.groupsAssignedToUser || [],
});

const mapDispatchToProps = (dispatch) => ({
  load_user: () => dispatch(load_user()),
  loadCalendarEventsByUser: (userId) => dispatch(loadCalendarEventsByUser(userId)),
  getGroupsAssignedToUser: (userId) => dispatch(getGroupsAssignedToUser(userId)), 
  clearCalendarEvents: () => dispatch(clearCalendarEvents()),
  handleInvitationCheck: (user_email) => dispatch(handleInvitationCheck(user_email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AthleteDashboard);
