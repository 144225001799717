import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import ReactQuill from 'react-quill'; // Import the ReactQuill component
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal'; // Import the react-modal library
import { updateCalendarEvent, addCalendarEvent, deleteCalendarEvent, 
  updateUserEventStatus, addUserEventStatus, deleteUserEventStatus, addGroupCalendarEvent, 
  loadCommentsForEvent, addCommentToEvent, getUserStatuses,
  clearUserStatus} from '../actions/auth';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import getLPTheme from '../containers/LandingPage/getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import DOMPurify from 'dompurify';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { formatDistanceToNow } from 'date-fns';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CheckCircleOutline, CancelOutlined } from '@mui/icons-material';
import { List, ListItem, ListItemText, ListItemIcon, Button } from '@mui/material';

const LPtheme = createTheme(getLPTheme('light'));

const localizer = momentLocalizer(moment);


const MyCalendar = ({ events, selectedUser, user, group, groupName, groupInfo, readOnly, usersInGroup, 
  updateCalendarEvent, addCalendarEvent, deleteCalendarEvent, addCommentToEvent,
                     updateUserEventStatus, addUserEventStatus, deleteUserEventStatus, 
                     addGroupCalendarEvent, athleteOrTeam, athelteStatusesOnGroupView, setAthelteStatusesOnGroupView }) => {
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState([]);
  //const [selectedEventStatus, setSelectedEventStatus] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isAddEventModalOpen, setIsAddEventModalOpen] = useState(false);
  const [displayedMonth, setDisplayedMonth] = useState(new Date());  // Store current displayed month
  const [newCommentText, setNewCommentText] = useState('');
  const [expandedComments, setExpandedComments] = useState({}); 
  //const [selectedEventId, setSelectedEventId] = useState(null); 
  const quillRef1 = useRef(null);
  const quillRef2 = useRef(null);
  const quillRef3 = useRef(null);

  const [eventData, addEventData] = useState({
    date: '', // Set an appropriate initial date value
    description: '',
    comments: '',
    title: '', 
    coach: null,
    group: group,
    user: user,
    type: '', 
  });
  const dispatch = useDispatch();
  const commentsForEvent = useSelector(state => state.auth.commentsForEvent);
  const userEventStatuses = useSelector(state => state.auth.userEventStatuses);
  
 
  useEffect(() => {
    if (selectedEvent.id) {
      // Load comments for the selected event when the modal opens
      dispatch(loadCommentsForEvent(selectedEvent.id));
      //prevEventIdRef.current = selectedEvent.id; // Update the ref with the current selectedEvent.id
    }
  }, [dispatch, selectedEvent?.id]);

  useEffect(() => {
    if (selectedEvent.id) {
      // Load user statuses for the selected event when the modal opens
      if (user.role === 'coach') {
        dispatch(getUserStatuses(selectedEvent.id, user, group));
      }
      else {
        dispatch(getUserStatuses(selectedEvent.id, selectedUser, group));
      }
        //prevEventIdRef.current = selectedEvent.id; // Update the ref with the current selectedEvent.id
    }
  }, [dispatch, selectedUser, group, selectedEvent?.id, user]);

  const handleDescriptionChange = (value) => {
    setSelectedEvent((prevEvent) => ({
      ...prevEvent,
      description: value,
    }));
  };
  
  useEffect(() => {
    if (events && events.length > 0) {
      //setDisplayedMonth(events.start);
      const allDayEvents = events.map(event => ({
        ...event,
        start: moment(event.date).startOf('day').toDate(), // Adjust to start of day
        end: moment(event.date).endOf('day').toDate(), // Adjust to end of day
        allDay: true, // Set allDay to true for full-day events
      }));

      setCalendarEvents(allDayEvents);
      
    }
  }, [events]);

  const handleAddComment = (eventId, reply) => {
    if (reply.trim() !== '') {
      addCommentToEvent(eventId, reply);
      setNewCommentText(''); // Clear the comment text after adding
    }
  };

  const openAddEventModal = (clickedDate) => {
    const formattedDate = clickedDate.toISOString().split('T')[0];
    addEventData({
      ...eventData,
      date: formattedDate,
    });
    setIsAddEventModalOpen(true);
  };

  const closeAddEventModal = () => {
    setIsAddEventModalOpen(false);
  };

  const handleAddEvent = async (e) => {
    e.preventDefault();
    console.log(groupInfo);
    console.log(eventData)
    // console.log(user)
    // console.log(usersInGroup)

    // Dispatch the action to add the event to the Redux store
    var response;
    if(athleteOrTeam === 'athlete'){
      console.log('athlete add')

      response = await addCalendarEvent(eventData, selectedUser);
      const newEvent = {
        ...eventData,
        start: new Date(eventData.date), // Convert date to Date object
        end: new Date(eventData.date), // Set end time (adjust as needed)
        allDay: true, // Set allDay to true for full-day events
      };
      setSelectedEvent(eventData);

      setCalendarEvents([...calendarEvents, newEvent]);

      const newUserEventStatus = {
        user: selectedUser.id,
        event: response.id, // Use the ID of the newly created CalendarEvent
        complete: false, // Adjust this value as needed
      };
      //console.log(newUserEventStatus);
      // Dispatch the action to add the UserEventStatus
      addUserEventStatus(newUserEventStatus);
      //setDisplayedMonth(newEvent.start);
      //console.log(newEvent.start);
      //console.log(displayedMonth);
      //handleNavigate(newEvent.start);

      //Clear the eventData state to reset the form
      addEventData({
        date: '',
        description: '',
        comments: '',
        title: '',
        coach: null,
        group: group,
        user: user,
        type: '', 
      });
  
      closeAddEventModal();
    }
    else{
      console.log('team add')
      response = await addGroupCalendarEvent(eventData, user, usersInGroup);
      const newEvent = {
        ...eventData,
        start: new Date(eventData.date), // Convert date to Date object
        end: new Date(eventData.date), // Set end time (adjust as needed)
        allDay: true, // Set allDay to true for full-day events
      };
  
      setCalendarEvents([...calendarEvents, newEvent]);
      //handleNavigate(newEvent.start);
  
      //Clear the eventData state to reset the form
      addEventData({
        date: '',
        description: '',
        comments: '',
        title: '',
        coach: null,
        group: group,
        user: user,
        type: '', 
      });
  
      closeAddEventModal();
    }

  };

  const handleEventClick = async (event) => {
    //console.log(athelteStatusesOnGroupView);
    // Check if none of the athelteStatusesOnGroupView results have event: event.id

  
    // Ensure userEventStatuses is loaded before proceeding
    if (user.role === 'player' && athleteOrTeam === 'team') {

        const hasEventStatus = athelteStatusesOnGroupView.some(status => status.event === event.id);
            if (!hasEventStatus){
              // Create a new user event status
              console.log("attempting status auto add");
              const newUserEventStatus = {
                user: user.id,
                event: event.id, // Use the ID of the newly created CalendarEvent
                complete: false, // Adjust this value as needed
              };
              // Dispatch the action to add the UserEventStatus
              addUserEventStatus(newUserEventStatus);  
              setAthelteStatusesOnGroupView(prevStatuses => [...prevStatuses, newUserEventStatus]);
          
            }
      }
      
  
    // Set the selected event and open the modal
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    dispatch(clearUserStatus());
    setIsModalOpen(false); // Close the modal
    setSelectedEvent({ 
      date: '', // Set an appropriate initial date value
      description: '',
      comments: '',
      title: '', 
      coach: null,
      group: group,
      user: user,
      type: '', }); // Clear selectedEvent
    //setSelectedEventStatus([]); // Clear selectedEvent
  };

  const handleEventEditSubmit = (e) => {
    
    e.preventDefault();

    updateCalendarEvent(selectedEvent);
    
    handleCloseModal();
  };

  const handleCompleteChange = (status) => {

    updateUserEventStatus(status);
  };
  

  const handleDeleteEvent = (e) => {
    e.preventDefault();
    // Dispatch the action to delete the event
    deleteCalendarEvent(selectedEvent.id); // Assuming you have a deleteCalendarEvent action
    // Remove the deleted event from the calendarEvents state

    // Check if UserEventStatus exists and delete it
    // if (userEventStatuses) {
    //   deleteUserEventStatus(userEventStatuses);
    // }
    setCalendarEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== selectedEvent.id)
    );
    // Close the modal
    handleCloseModal();
  };
  
  const handleEventStyleGetter = (event) => {
    const typeColorMap = {
      'Breathwork': '#82C5CE',
      'Focus': '#2AB77C',
      'Stress Management': '#BDBB4D',
      'Reflection': '#EF5B5B',
      'Imagery': '#FF9F1C',
      'Goal Setting': '#9D79BC',
      'Other': '#A86084',
    };

    
    return {
      style: {
        backgroundColor: typeColorMap[event.type],
      },
    };
  };

  const sanitizeHtml = (html) => {
    // Replace <p> tags with <div> tags
    if (!html) {
      return '';
    }
    const replacedHtml = html.replace(/<p>/g, '<div>').replace(/<\/p>/g, '</div>');
    // Sanitize the replaced HTML content
    const sanitizedHtml = DOMPurify.sanitize(replacedHtml);
    return sanitizedHtml;
  };

  // const handleNavigate = (date) => {
  //   //console.log(displayedMonth);
  //   date = new Date(displayedMonth);
  //   //setDisplayedMonth(date);
  // };

  const customToolbar = (toolbar) => {
  
      const goToBack = () => {
        const newDate = new Date(toolbar.date);
        newDate.setMonth(newDate.getMonth() - 1);
        setDisplayedMonth(new Date(newDate));
        toolbar.onNavigate('prev', newDate);
      };
  
      const goToNext = () => {
        const newDate = new Date(toolbar.date);
        newDate.setMonth(newDate.getMonth() + 1);
        //console.log("gotonext");
        //console.log(newDate);
        setDisplayedMonth(new Date(newDate));
        toolbar.onNavigate('next', newDate);
      };
  
      const goToCurrent = () => {
        const now = new Date();
        toolbar.date.setMonth(now.getMonth());
        toolbar.date.setYear(now.getFullYear());
        setDisplayedMonth(new Date(now));
        toolbar.onNavigate('current');
      };
  
      const label = () => {
        const date = moment(toolbar.date);
        return (
          <span>
            <b>{date.format('MMMM')}</b>
            <span> {date.format('YYYY')}</span>
          </span>
        );
      };
  
    return (
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <h2 className="mb-4">
              {group ? `${groupName}'s Calendar` : `${selectedUser && selectedUser.first_name} ${selectedUser && selectedUser.last_name}'s Calendar`}
            </h2>
          </div>
          <div className="col-md-4 text-center">
            <h4 className="mb-4">{label()}</h4>
          </div>
          <div className="col-md-4 text-right">
            <div className="btn-group align-self-center">
              <button className="btn btn-primary" hidden={readOnly} 
              onClick={() => openAddEventModal(new Date())}
              disabled= {!(athleteOrTeam === 'athlete' || (athleteOrTeam === 'team' && usersInGroup.length !== 0))}
              >Add Event</button>
              <button className="btn btn-secondary" onClick={goToBack}>Back</button>
              <button className="btn btn-primary" onClick={goToCurrent}>Today</button>
              <button className="btn btn-secondary" onClick={goToNext}>Next</button>
            </div>
          </div>
        </div>
      <br />
      </div>
    );
  };

  const toggleExpand = (comment) => {
    setExpandedComments((prevExpandedComments) => ({
      ...prevExpandedComments,
      [comment.id]: !prevExpandedComments[comment.id], // Toggle based on comment id
    }));
  };

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)', // Change overlay background color and opacity
      zIndex: 1000, // Set a higher z-index to ensure modal is on top of other elements
    },
    content: {
      width: '60%', // Set the width of the modal
      maxWidth: '500px', // Set a maximum width
      margin: '0 auto', // Center the modal horizontally
      padding: '20px', // Add padding for better spacing
    },
  };
  const commentToolbarModules = {
    toolbar: false,
  };

  return (
    <div>
    <style>
    {`
      .rbc-month-row {
        display: inline-table !important;
        height: 50px !important;
        min-height: 100px !important;
        width: 100% !important;
      }
      
      .ql-container.ql-snow {
        height: 150px;
        overflowY: scroll;
      }
      .rbc-event {
        display: grid;
        max-width: 100%; /* Set a maximum width for event elements */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    `}
    </style>
      

    <ThemeProvider theme={LPtheme}>
    <CssBaseline />

      <div>
        <Calendar
          localizer={localizer}
          events={calendarEvents}
          startAccessor="start"
          endAccessor="end"
          titleAccessor={(event) => (
            <>
              <div style={{ fontSize: '14px', textAlign: 'center' }}>{event.type}</div>
              <div style={{ fontSize: '12px', textAlign: 'left', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'  }}>{event.title}</div>
              <div style={{ fontSize: '12px', textAlign: 'left', overflow: 'hidden', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 2, height: '3em' }} dangerouslySetInnerHTML={{ __html: sanitizeHtml(event.description) }} />
             
              </>
          )}
          style={{ color: 'black' }}
          selectable
          onSelectSlot={readOnly ? null : (slotInfo) => openAddEventModal(slotInfo.start)}
          onSelectEvent={handleEventClick}
          views={['month' ]}
          eventPropGetter={handleEventStyleGetter}
          components={{
            toolbar: customToolbar,
          }}
          popup={true}
          //date={displayedMonth}
          //onNavigate={handleNavigate}
          
        />
        
      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Event Details"
        style={modalStyles}
      >
        <div>
      {readOnly ? (
                      // Render checkbox for read-only false
                      <FormControlLabel
                      control={
                        <Checkbox
                          checked={userEventStatuses[0] ? userEventStatuses[0].complete : false}
                          onChange={(e) => {
                            if (userEventStatuses[0]) {
                              const updatedStatus = [...userEventStatuses];  // Copy the array to preserve immutability
                              updatedStatus[0] = { ...updatedStatus[0], complete: e.target.checked };  // Update the first object
                              handleCompleteChange(updatedStatus[0]);  // Call the function with the updated object
                            }
                          }}
                          name="complete"
                          color="primary" // Change color if needed
                        />
                      }
                      label="Complete"
                    />
                  ) : (
                    <div>
                      {athleteOrTeam === 'athlete' ? (
                      <Typography variant="body1">
                        {userEventStatuses[0] && userEventStatuses[0].complete ? (
                          <>
                          <CheckCircleOutline color="success" /> 
                          {` ${selectedUser && selectedUser.first_name} ${selectedUser && selectedUser.last_name}`}
                          </>
                        ) : (
                          <>
                          <CancelOutlined color="error" /> 
                          {` ${selectedUser && selectedUser.first_name} ${selectedUser && selectedUser.last_name}`}
                          </>
                        )}
                      </Typography>
                      ) : (
                        // Display group completion information
                      // Add a wrapper with scrollable styling
                      <div style={{ maxHeight: '150px', overflowY: 'auto'}}>
                      {athleteOrTeam === 'team'  ? (
                        <List>
                          {userEventStatuses && userEventStatuses.map((status) => {
                            // Find the user object with matching ID
                            const matchedUser = usersInGroup.find((users) => users.id === status.user);
                    
                            return (
                              <ListItem key={status.id} disablePadding>
                                <ListItemIcon>
                                  {status.complete ? (
                                    <CheckCircleOutline color="success" /> // Display checkmark icon for completed
                                  ) : (
                                    <CancelOutlined color="error" /> // Display x icon for unfinished
                                  )}
                                </ListItemIcon>
                                <ListItemText>
                                  <Typography variant="body1">
                                    {matchedUser ? `${matchedUser.first_name} ${matchedUser.last_name}` : 'Error finding name'}
                                  </Typography>
                                </ListItemText>
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : (
                        <p>Completion status not available</p>
                      )}
                    </div>
                    

                      )}
                    </div>
                  )}
                  </div>
       <form onSubmit={handleEventEditSubmit}>
       
          {selectedEvent && userEventStatuses && userEventStatuses.length > 0 ? (
            <div className="event-details">
              <h3>{selectedEvent.title}</h3>
              {/* <div className="form-check">
                  
              </div> */}
              <br />
              <div className="form-group">
                {/* <label htmlFor="descriptionTextarea">Description:</label> */}
                {readOnly ? (
                  <Card variant="outlined">
                  <CardContent style={{ height: "8em", overflowY: "auto" }}>
                    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(selectedEvent?.description || '') }} />
                  </CardContent>
                </Card>
                ) : (
                  <ReactQuill
                    theme="snow" 
                    ref={quillRef1} 
                    readOnly={false}
                    //style={{ height: "10em", overflowY: "auto" }} 
                    id="descriptionTextarea"
                    value={selectedEvent?.description || ''}
                    onChange={handleDescriptionChange}
                    
                  />
                  
                )}
              </div>
              <div className="comments-section" style={{ maxHeight: '500px', overflowY: 'auto' }}>
                  
                  {commentsForEvent && commentsForEvent.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {commentsForEvent.map((comment, index) => (
                      <li key={comment.id || index}>
                                   <Card variant="outlined">
                          <CardContent>
                          <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                      {user.id === comment.user ? 'Me' : comment.username}
                    </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                maxHeight: expandedComments[comment.id] ? 'none' : '80px',
                                overflowY: 'hidden', // Remove scrolling
                                fontSize: '14px'
                              }}
                              dangerouslySetInnerHTML={{ __html: sanitizeHtml(comment.reply) }}
                            />
                            {comment.reply.length > 200 && (
                              <Button onClick={() => toggleExpand(comment)} color="primary" size="small">
                                {expandedComments[comment.id] ? 'Show Less' : 'Show More'}
                              </Button>
                            )}
                            <Typography variant="caption">{formatDistanceToNow(new Date(comment.time), { addSuffix: true })}</Typography>
                          </CardContent>
                        </Card>

                      </li>
                    ))}

                  </ul>
                  ) : (
                    <p>No comments</p>
                  )}
                </div>

                <div className="add-comment" style={{ marginTop: '10px' }}>
                <ReactQuill
                    theme="snow"
                    ref={quillRef2} 
                    value={newCommentText}
                    onChange={(value) => setNewCommentText(value)}
                    placeholder="Add a comment..."
                    readOnly={false}
                    modules = {commentToolbarModules}
                    // Hide toolbar by not providing modules.toolbar property
                  />
                  <button
                    type='button'
                    onClick={() => handleAddComment(selectedEvent.id, newCommentText)}
                    style={{ marginTop: '10px', padding: '10px', backgroundColor: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
                  >
                    Reply
                  </button>
                </div>


                    <br />
              <button type="submit" className="btn btn-primary" hidden={readOnly}>
                Save
              </button>
             
              <button onClick={handleCloseModal} className="btn btn-secondary mx-2" >Close</button>
              <button onClick={handleDeleteEvent} className="btn btn-danger" hidden={readOnly}>Delete Event</button>
            </div>
          ): (
            <p>No statuses available</p>
          )}
        </form>

      </Modal>

      <Modal
        isOpen={isAddEventModalOpen}
        onRequestClose={closeAddEventModal}
        contentLabel="Add Event"
        style={modalStyles}
      >
       <form onSubmit={handleAddEvent}>
          <div className="event-add-details">
            <h3>Add New Event</h3>
            <div className="form-group">
              <label htmlFor="dateInput">Date:</label>
              <input
                className="form-control"
                type="date"
                id="dateInput"
                value={eventData.date || ''}
                onChange={(e) =>
                  addEventData({
                    ...eventData,
                    date: e.target.value,
                  })
                }
                required // Mark the field as required
              />
            </div>
            <div className="form-group">
              <label htmlFor="titleInput">Title:</label>
              <input
                className="form-control"
                type="text"
                id="titleInput"
                value={eventData.title || ''}
                onChange={(e) =>
                  addEventData({
                    ...eventData,
                    title: e.target.value,
                  })
                }
                required // Mark the field as required
              />
            </div>
            <div className="form-group">
              <label htmlFor="typeSelect">Type:</label>
              <select
                className="form-control"
                id="typeSelect"
                value={eventData.type || ''}
                onChange={(e) =>
                  addEventData({
                    ...eventData,
                    type: e.target.value,
                  })
                }
              >
                <option value="">Select Type</option>
                <option value="Breathwork">Breathwork</option>
                <option value="Focus">Focus</option>
                <option value="Stress Management">Stress Management</option>
                <option value="Reflection">Reflection</option>
                <option value="Imagery">Imagery</option>
                <option value="Goal Setting">Goal Setting</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="descriptionTextarea">Description:</label>
              <ReactQuill
              ref={quillRef3} 
                theme="snow" 
                value={eventData.description || ''}
                onChange={(value) =>
                  addEventData({
                    ...eventData,
                    description: value,
                  })
                }
              />
            </div>
            {  athleteOrTeam === 'athlete' || (athleteOrTeam === 'team' && usersInGroup.length !== 0)  ? (
            <button type="submit" className="btn btn-primary mx-2">Add Event</button>
          ) : (
            <p>No Athletes are in group. Cannot add event.</p>
          )} 
            <button onClick={closeAddEventModal} className="btn btn-secondary mx-2">Close</button>
          </div>
        </form>

      </Modal>
    <br />
    </div>
        </ThemeProvider>
        </div>
  );
};

const mapStateToProps = (state) => ({
  userEventStatuses: state.auth.userEventStatuses || [],
  usersInGroup: state.auth.usersInGroup || [],
  user: state.auth.user,
  calendarEvents: state.auth.calendarEvents,
  groupsAssignedToCoach: state.auth.groupsAssignedToCoach || [],
  groupInfo: state.auth.groupInfo || [],
});

const mapDispatchToProps = (dispatch) => ({
  updateCalendarEvent: (eventData) => dispatch(updateCalendarEvent(eventData)),
  addCalendarEvent: (eventData, user) => dispatch(addCalendarEvent(eventData, user)),
  deleteCalendarEvent: (eventData) => dispatch(deleteCalendarEvent(eventData)),
  addGroupCalendarEvent: (eventData, user, usersInGroup) => dispatch(addGroupCalendarEvent(eventData, user, usersInGroup)),
  addUserEventStatus: (userEventStatusData) => dispatch(addUserEventStatus(userEventStatusData)),
  deleteUserEventStatus: (userEventStatusData) => dispatch(deleteUserEventStatus(userEventStatusData)),
  updateUserEventStatus: (userEventStatusData) => dispatch(updateUserEventStatus(userEventStatusData)),
  addCommentToEvent: (eventId, reply) => dispatch(addCommentToEvent(eventId, reply)),
  getUserStatuses: (eventId, selectedUser, group) => dispatch(getUserStatuses(eventId, selectedUser, group)),
  loadCommentsForEvent: (eventId) => dispatch(loadCommentsForEvent(eventId)),
  clearUserStatus: () => dispatch(clearUserStatus()),
  //getUserStatusesExists: (eventId, user) => dispatch(getUserStatusesExists(eventId, user)),
}); 

export default connect(mapStateToProps, mapDispatchToProps)(MyCalendar);
