import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reset_password } from '../actions/auth';

import getLPTheme from '../containers/LandingPage/getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const LPtheme = createTheme(getLPTheme('light'));

const ResetPassword = ({ reset_password }) => {
    const [formData, setFormData] = useState({
        email: ''
    });
    const [resetMessage, setResetMessage] = useState('');
    const [error, setError] = useState('');

    const { email } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();

        try {
            await reset_password(email);  // Assuming reset_password returns a promise
            setResetMessage('An email has been sent to reset your password. Please check your inbox.');
            setError('');  // Clear any previous error messages
        } catch (error) {
            setError('An error occurred while sending the reset email. Please try again.'); // Handle any errors
            setResetMessage(''); // Clear the success message if there's an error
        }
    };

    return (
        <ThemeProvider theme={LPtheme}>
            <CssBaseline />
            <div className='container mt-5'>
                <h1>Request Password Reset:</h1>
                {resetMessage && (
                    <div className="alert alert-success mt-3">
                        {resetMessage}
                    </div>
                )}
                {error && (
                    <div className="alert alert-danger mt-3">
                        {error}
                    </div>
                )}
                <form onSubmit={e => onSubmit(e)}>
                    <div className='form-group'>
                        <input
                            className='form-control'
                            type='email'
                            placeholder='Email'
                            name='email'
                            value={email}
                            onChange={e => onChange(e)}
                            required
                        />
                    </div>
                    <button className='btn btn-primary' type='submit'>Reset Password</button>
                </form>
            </div>
        </ThemeProvider>
    );
};

export default connect(null, { reset_password })(ResetPassword);
