import React from 'react';
const AdminPage = () => {


  return (
    <div>
     
        <div style={{ height: '100vh', width: '100vw' }}>
          <iframe
            src="https://themindbuilder.org/accounts/admin/"
            //src="http://127.0.0.1:8000/accounts/admin/"
            style={{ height: '100%', width: '100%', border: 'none' }}
            title="Django Admin"
          />
        </div>
     
    </div>
  );
};

export default AdminPage;
