import React, { useEffect, useState } from 'react';
//import { useParams } from 'react-router-dom';
import api from '../actions/api-config';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import getLPTheme from '../containers/LandingPage/getLPTheme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

const LPtheme = createTheme(getLPTheme('light'));

const AthleteProfile = ({ user }) => {
  const [userCoach, setUserCoach] = useState(null);
  const [coachFetched, setCoachFetched] = useState(false); // Track if coach data has been fetched

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `JWT ${localStorage.getItem('access')}`,
            'Accept': 'application/json'
          }
        };

        // Only fetch coach data if it hasn't been fetched yet
        if (!coachFetched && user) {
          const response = await api.get(`/accounts/coach-by-user/${user.id}/`, config);
          setUserCoach(response.data[0]);
          setCoachFetched(true); // Set coachFetched to true after fetching data
        }
      } catch (error) {
        console.error('Error fetching coach:', error);
      }
    };

    fetchUserData(); // Call the fetch function directly

  }, [user, coachFetched]); // Depend on coachFetched to avoid repeated API calls

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <ThemeProvider theme={LPtheme}>
      <CssBaseline />
      <div className="container mt-4">
        <h2>Athlete Profile</h2>
        {user ? (
          <div>
            <ul className="list-group">
              <li className="list-group-item bg-transparent">Name: {user.first_name} {user.last_name}</li>
              <li className="list-group-item bg-transparent">Email: {user.email}</li>
              <li className="list-group-item bg-transparent">About: {user.about}</li>
            </ul>
            <br />
            {userCoach && ( // Check if coach data is available
              <div>
                <h3>Coach Info</h3>
                <ul className="list-group">
                  <li className="list-group-item bg-transparent">Name: {userCoach.first_name} {userCoach.last_name}</li>
                <li className="list-group-item bg-transparent">Email: {userCoach.email}</li>
                <li className="list-group-item bg-transparent">About: {userCoach.about}</li>
                </ul>
              </div>
            )}
          </div>
        ) : (
          <p>Loading user data...</p>
        )}
      </div>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(AthleteProfile);
