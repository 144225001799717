import React, { Fragment, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../actions/auth';

const Navbar = ({ logout, isAuthenticated, userRole }) => {
    const location = useLocation();
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

    const logout_user = async () => {
        await logout();
    };

    const guestLinks = () => (
        <Fragment>
            <li className='nav-item'>
                <Link className={`nav-link ${location.pathname === '/login' ? 'active' : ''}`} to='/login'>Login</Link>
            </li>
            <li className='nav-item'>
                <Link className={`nav-link ${location.pathname === '/signup' ? 'active' : ''}`} to='/signup'>Sign Up</Link>
            </li>
        </Fragment>
    );

    const authLinks = (userRole) => (
        <Fragment>
            {userRole === 'coach' ? (
                <li className='nav-item'>
                    <Link className={`nav-link ${location.pathname === '/coach-dashboard' ? 'active' : ''}`} to='/coach-dashboard'>Dashboard</Link>
                </li>
            ) : (
                <li className='nav-item'>
                    <Link className={`nav-link ${location.pathname === '/athlete-dashboard' ? 'active' : ''}`} to='/athlete-dashboard'>Dashboard</Link>
                </li>
            )}
            {userRole === 'coach' ? (
                <li className='nav-item'>
                    <Link className={`nav-link ${location.pathname === '/coach-profile' ? 'active' : ''}`} to='/coach-profile'>Profile</Link>
                </li>
            ) : (
                <li className='nav-item'>
                    <Link className={`nav-link ${location.pathname === '/athlete-profile' ? 'active' : ''}`} to='/athlete-profile'>Profile</Link>
                </li>
            )}
            <li className='nav-item'>
                <a className='nav-link' href='/' onClick={logout_user}>Logout</a>
            </li>
        </Fragment>
    );

    return (
        <Fragment>
            {/* <div style={{ backgroundColor: 'pink', padding: '10px', textAlign: 'center', color: 'white' }}>
                This website is under development. Please email cchgray@gmail.com with any bugs or suggestions.
            </div> */}
            <nav className='navbar navbar-expand-lg navbar-light' style={{ backgroundColor: '#B7D9F1' }}>
                <Link className='navbar-brand' to='/'>Mind Builder</Link>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-toggle='collapse'
                    data-target='#navbarNav'
                    aria-controls='navbarNav'
                    aria-expanded={!isNavCollapsed ? true : false}
                    aria-label='Toggle navigation'
                    onClick={handleNavCollapse}
                >
                    <span className='navbar-toggler-icon'></span>
                </button>
                <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse justify-content-end`} id='navbarNav'>
                    <ul className='navbar-nav'>
                        {isAuthenticated ? authLinks(userRole) : guestLinks()}
                    </ul>
                </div>
            </nav>
        </Fragment>
    );
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    userRole: state.auth.user ? state.auth.user.role : null,
});

export default connect(mapStateToProps, { logout })(Navbar);
