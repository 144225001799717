// api-config.js
import axios from 'axios';

//for local testing
//axios.defaults.baseURL = 'http://127.0.0.1:8000';


//for production
axios.defaults.baseURL = 'https://themindbuilder.org';


export default axios;
