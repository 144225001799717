export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const USER_LOADED_SUCCESS = 'USER_LOADED_SUCCESS';
export const USER_LOADED_FAIL = 'USER_LOADED_FAIL';
export const AUTHENTICATED_SUCCESS = 'AUTHENTICATED_SUCCESS';
export const AUTHENTICATED_FAIL = 'AUTHENTICATED_FAIL';
export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const GOOGLE_AUTH_SUCCESS = 'GOOGLE_AUTH_SUCCESS';
export const GOOGLE_AUTH_FAIL = 'GOOGLE_AUTH_FAIL';
export const FACEBOOK_AUTH_SUCCESS = 'FACEBOOK_AUTH_SUCCESS';
export const FACEBOOK_AUTH_FAIL = 'FACEBOOK_AUTH_FAIL';
export const LOGOUT = 'LOGOUT';
export const LOAD_CALENDAR_EVENTS_SUCCESS = 'LOAD_CALENDAR_EVENTS_SUCCESS';
export const UPDATE_CALENDAR_EVENT_SUCCESS = 'UPDATE_CALENDAR_EVENT_SUCCESS';
export const ADD_CALENDAR_EVENT_SUCCESS = 'ADD_CALENDAR_EVENT_SUCCESS';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';
export const LOAD_USERS_BY_COACH_SUCCESS = 'LOAD_USERS_BY_COACH_SUCCESS';
export const ACCEPT_COACH_REQUEST = 'ACCEPT_COACH_REQUEST';
export const CLEAR_CALENDAR_EVENTS = 'CLEAR_CALENDAR_EVENTS'; 
export const LOAD_GROUPS_ASSIGNED_TO_COACH_SUCCESS = 'LOAD_GROUPS_ASSIGNED_TO_COACH_SUCCESS';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const LOAD_USERS_IN_GROUP_SUCCESS = 'LOAD_USERS_IN_GROUP_SUCCESS';
export const LOAD_USERS_IN_GROUP_FAIL = 'LOAD_USERS_IN_GROUP_FAIL';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAIL = 'ADD_USER_TO_GROUP_FAIL';
export const REMOVE_USER_FROM_GROUP_SUCCESS = 'REMOVE_USER_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_FAIL = 'REMOVE_USER_FROM_GROUP_FAIL';
export const FETCH_GROUP_INFO_SUCCESS = 'FETCH_GROUP_INFO_SUCCESS';
export const FETCH_GROUP_INFO_FAILURE = 'FETCH_GROUP_INFO_FAILURE';
export const LOAD_CALENDAR_EVENTS_BY_GROUP_SUCCESS = 'LOAD_CALENDAR_EVENTS_BY_GROUP_SUCCESS';
export const UPDATE_USER_EVENT_STATUS_SUCCESS = 'UPDATE_USER_EVENT_STATUS_SUCCESS';
export const DELETE_USER_EVENT_STATUS_SUCCESS = 'DELETE_USER_EVENT_STATUS_SUCCESS';
export const ADD_USER_EVENT_STATUS_SUCCESS = 'ADD_USER_EVENT_STATUS_SUCCESS';
export const LOAD_GROUPS_ASSIGNED_TO_USER_SUCCESS = 'LOAD_GROUPS_ASSIGNED_TO_USER_SUCCESS';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const FETCH_NOTES_SUCCESS = 'FETCH_NOTES_SUCCESS';
export const FETCH_NOTES_FAILURE = 'FETCH_NOTES_FAILURE';
export const UPDATE_NOTES_SUCCESS = 'UPDATE_NOTES_SUCCESS';
export const UPDATE_NOTES_FAILURE = 'UPDATE_NOTES_FAILURE';
export const REMOVE_COACH_ASSIGNMENT_SUCCESS = 'REMOVE_COACH_ASSIGNMENT_SUCCESS';
export const REMOVE_COACH_ASSIGNMENT_FAILURE = 'REMOVE_COACH_ASSIGNMENT_FAILURE';
export const INVITE_USER_SUCCESS = 'INVITE_USER_SUCCESS';
export const INVITE_USER_FAILURE = 'INVITE_USER_FAILURE';
export const LOAD_COMMENTS_SUCCESS = 'LOAD_COMMENTS_SUCCESS';
export const ADD_COMMENT_TO_EVENT_SUCCESS = 'ADD_COMMENT_TO_EVENT_SUCCESS';
export const FETCH_PENDING_INVITATIONS_SUCCESS = 'FETCH_PENDING_INVITATIONS_SUCCESS';
export const FETCH_PENDING_INVITATIONS_FAILURE = 'FETCH_PENDING_INVITATIONS_FAILURE';
export const REMOVE_INVITATION_SUCCESS = 'REMOVE_INVITATION_SUCCESS';
export const INVITE_MULTIPLE_USERS_SUCCESS = 'INVITE_MULTIPLE_USERS_SUCCESS';
export const INVITE_MULTIPLE_USERS_FAILURE = 'INVITE_MULTIPLE_USERS_FAILURE';
export const AUTO_ASSIGN_USER_TO_GROUP_SUCCESS = 'AUTO_ASSIGN_USER_TO_GROUP_SUCCESS';
export const GET_USER_EVENT_STATUS_SUCCESS = 'GET_USER_EVENT_STATUS_SUCCESS';
export const GET_USER_EVENT_STATUS_FAILURE = 'GET_USER_EVENT_STATUS_FAILURE';
export const CLEAR_GROUP_INFO = 'CLEAR_GROUP_INFO';
export const CLEAR_USER_STATUS = 'CLEAR_USER_STATUS';
