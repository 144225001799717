import * as React from 'react';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function SectionC() {
  return (
    <Container className='SectionC' sx={{ py: { xs: 6, sm: 6 } }}>
      <Typography component="h2" variant="h4" color="text.primary" p={2} gutterBottom>Schedule a Demo Call</Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <Card sx={{ backgroundColor: '#E2E7E4' }}>
            <Grid container direction="column" p={2}>
              {/* Top section */}
              <Grid item>
                <Typography variant="h4" gutterBottom>
                  Discover the benefits of Mind Builder
                </Typography>
                <Typography variant="body1" p={2} gutterBottom>
                  Want to see Mind Builder in action? Schedule a 30-minute demo call with us to learn how our software can help you enhance mental performance and sports psychology practices.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  href="https://calendly.com/themindbuildersoftware/the-mind-builder-demo-call?month=2024-09"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  
                  Schedule a Demo
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
